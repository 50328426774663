import type { AnimatedProductCategory } from '@backmarket/nuxt-layer-cms/BaseAnimatedProductCheckpoints/types'

import { ProductCategory } from '~/scopes/product/constants'

export const CATEGORIES_MAP: Record<string, AnimatedProductCategory> = {
  [ProductCategory.SMARTPHONE]: 'smartphones',
  [ProductCategory.MACBOOK_INTEL]: 'laptops',
  [ProductCategory.MACBOOK_M]: 'laptops',
  [ProductCategory.IPAD]: 'tablets',
}
