<template>
  <div class="text-static-default-hi body-2-bold mb-28 md:mb-32">
    <div class="mb-12 flex flex-col gap-12 md:mb-8 md:flex-row md:gap-8">
      <ItemShipping
        :class="[{ 'md:w-1/2': shouldDisplayCarriers }]"
        :shipping
      />
      <ItemCarrier :carrier class="md:w-1/2" />
    </div>
    <div class="flex flex-col gap-12 md:flex-row md:gap-8">
      <ItemReassurance class="md:w-1/2" :tracking :warranty-delay />
      <ItemVerifiedRefurbished
        v-if="showVerifiedRefurbished"
        class="md:w-1/2"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import ItemCarrier from './components/ItemCarrier/ItemCarrier.vue'
import ItemReassurance from './components/ItemReassurance/ItemReassurance.vue'
import ItemShipping from './components/ItemShipping/ItemShipping.vue'
import ItemVerifiedRefurbished from './components/ItemVerifiedRefurbished/ItemVerifiedRefurbished.vue'

const props = withDefaults(
  defineProps<{
    carrier: GetProductResponse['carrier']
    shipping: NonNullable<GetPickersResponse['selectedOffer']>['shipping']
    warrantyDelay?: string | null
    showVerifiedRefurbished?: boolean
  }>(),
  {
    warrantyDelay: undefined,
    showVerifiedRefurbished: false,
  },
)

const tracking = computed(() => {
  return { zone: 'return_info', name: '30_days_return' }
})

const shouldDisplayCarriers = computed(() => {
  return !isEmpty(props.carrier) && props.carrier.isDisplayed
})
</script>
