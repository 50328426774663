<template>
  <StepLayout
    class="mb-56"
    :slot-classes="{
      left: 'left-0 top-[78px] flex justify-center md:sticky',
      right: 'md:pt-72',
    }"
    stretch
    :tracking="trackingStepLayout"
    :without-left-template
  >
    <template #left>
      <Gallery
        :allow-media-viewer="false"
        :allow-partners-images="false"
        class="mb-16 w-full"
        :images
        :product
        :tracking-product-model="product.model"
        tracking-zone="pp_recap_carousel"
      />
    </template>

    <template #right>
      <h2 class="punchline mb-24 text-center md:text-left">
        {{ i18n(translations.resultTitle) }}
      </h2>

      <div class="mb-24 flex justify-center md:hidden">
        <RevIllustration
          v-if="product.images?.[0]"
          :alt="product.images[0].description"
          class="mb-16"
          :height="224"
          :src="product.images[0].url"
          :width="224"
        />
      </div>

      <div class="relative">
        <div
          class="bg-surface-default-low border-static-default-hi rounded-sm relative border p-12 pt-24"
        >
          <div class="heading-3">
            {{ product.titles.default }}

            <div
              v-if="selectedColorCode"
              aria-hidden
              class="rounded-full ml-8 inline-block size-[12px]"
              :style="{ background: selectedColorCode }"
            />
          </div>

          <div v-if="selectedOptions" class="mt-6 flex flex-wrap gap-8">
            <RevTag
              v-for="option in selectedOptions"
              :key="option"
              :label="option"
              variant="secondary"
              variation="outline"
            />
          </div>

          <Price
            class="mt-12"
            :mobile-plan
            :price
            :price-due-today="priceWithoutSubsidies"
            :product
            :selected-offer
          />

          <MobilePlan v-if="mobilePlan" class="mt-12" :plan="mobilePlan" />
        </div>
      </div>

      <div>
        <RevButton
          class="mb-8 mt-12"
          full-width="always"
          variant="primary"
          @click="onAddToCart"
        >
          {{ i18n(translations.resultAddToCart) }}
        </RevButton>

        <Seller :category="product.type" :selected-offer />

        <ClientOnly>
          <p>
            <PaymentIcons :methods="[...paymentMethods, ...paymentMethods]" />
          </p>
        </ClientOnly>
      </div>

      <div class="body-2-bold mb-56 mt-24 flex flex-col gap-12">
        <ItemShipping :shipping="selectedOffer.shipping" />
        <ItemCarrier
          :carrier="product.carrier"
          :product-brand="product.brand"
          :product-category="product.tracking.categoryId"
        />
        <ItemReassurance
          :tracking="{ zone: 'return_info', name: '30_days_return_recap' }"
          :warranty-delay="selectedOffer.defaultWarrantyDelay"
        />

        <Vat v-if="isVatIncluded" class="mt-24" />
      </div>

      <slot name="details" />
    </template>
  </StepLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type Price as PriceType } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { PaymentIcons } from '@backmarket/nuxt-layer-payment/methods'
import { usePaymentMethodsWithFallback } from '@backmarket/nuxt-layer-payment/methods/composables/usePaymentMethodsWithFallback'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

import { useAddToCart } from '../../../../composables/useAddToCart'
import { useProductTracking } from '../../../../composables/useProductTracking'
import Gallery from '../../../Gallery/Gallery.vue'
import Price from '../../../Price/Price.vue'
import ItemCarrier from '../../../ReassuranceItems/components/ItemCarrier/ItemCarrier.vue'
import ItemReassurance from '../../../ReassuranceItems/components/ItemReassurance/ItemReassurance.vue'
import ItemShipping from '../../../ReassuranceItems/components/ItemShipping/ItemShipping.vue'
import Vat from '../../../Vat/Vat.vue'
import StepLayout from '../StepLayout/StepLayout.vue'

import translations from './Proposal.translations'
import MobilePlan from './components/MobilePlan/MobilePlan.vue'
import Seller from './components/Seller/Seller.vue'

const props = withDefaults(
  defineProps<{
    selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
    isFocused?: boolean
    product: GetProductResponse
    price: PriceType
    priceWithoutSubsidies?: PriceType | null
    mobilePlan?: MobilePlanOffer
    selectedOptions: Array<string>
    selectedColorCode?: string
    tracking: ReturnType<typeof useProductTracking>
    withoutLeftTemplate?: boolean
  }>(),
  {
    isFocused: true,
    selectedColorCode: undefined,
    mobilePlan: undefined,
    priceWithoutSubsidies: undefined,
    withoutLeftTemplate: false,
  },
)

const marketplace = useMarketplace()
const { addToCart } = useAddToCart()
const i18n = useI18n()
const paymentMethods = usePaymentMethodsWithFallback(
  () => props.selectedOffer.paymentMethods,
)

const isVatIncluded = computed(() => {
  return marketplace.features.vatIncluded
})

const images = computed(() => {
  return props.product.images.slice(0, 3)
})

const trackingStepLayout = computed(() => {
  return {
    trackingId: 'recap',
    trackingModel: props.product.model,
    trackingCategory: props.product.tracking.categoryName,
  }
})

function onAddToCart() {
  addToCart({
    listingId: props.selectedOffer.offerLegacyId,
    listingPublicId: props.selectedOffer.offerId,
    tracking: {
      ...props.tracking.product.value,
      list: 'recap',
    },
    partnerPromoCodes: props.product.includedServiceOffers.partnerPromoCodes,
    isTradeInEligible: props.product.isTradeInEligible,
  })
}
</script>
