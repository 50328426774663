<template>
  <ReassuranceItem>
    <template #icon>
      <IconOfficial />
    </template>
    <template #content>
      <div class="grow">
        <button
          class="body-2 flex w-full items-center justify-between text-left"
          @click="onClick"
        >
          <span>
            {{ i18n(translations.verified) }}
          </span>

          <IconChevronRight />
        </button>
      </div>
      <VerifiedRefurbishedModal />
    </template>
  </ReassuranceItem>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconOfficial } from '@ds/icons/IconOfficial'

import { MODAL_NAMES } from '~/scopes/product/constants'

import ReassuranceItem from '../ReassuranceItem/ReassuranceItem.vue'
import VerifiedRefurbishedModal from '../VerifiedRefurbishedModal/VerifiedRefurbishedModal.vue'

import translations from './ItemVerifiedRefurbished.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

function onClick() {
  openModal(MODAL_NAMES.VERIFIED_REFURBISHED)
  trackClick({
    zone: 'verified_refurbished_info',
    name: 'verified_refurbished',
  })
}
</script>
